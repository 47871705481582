<template>
  <div>
    <v-overlay v-model="overlay"  style="z-index: 999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-toolbar flat color="white">
        <!-- <v-col cols="4">
          <date-picker
            class="mt-4"
            v-model="searchFecha"
            valueType="YYYY/MM/DD HH:MM"
            time-title-format="YYYY/MM/DD HH:MM"
            :show-second="false"
            @change="cargarListaEstadoCuenta(true)"
            :range="true"
            type="datetime"
            placeholder="Fecha trans."
            :show-time-panel="false"
          >
            <template v-slot:footer="{ emit }">
              <button
                class="mx-btn"
                @click="showTimeRangePanel = !showTimeRangePanel"
              >
                {{ showTimeRangePanel ? "Fecha" : "Tiempo" }}
              </button>
              <button class="mx-btn" @click="selectToDay(emit)">Hoy</button>
            </template>
          </date-picker>
        </v-col> -->
        <v-col cols="2">
          
          <v-autocomplete
            :items="glRec"
            item-text="gl_reconciliation_name"
            item-value="gl_reconciliation_id"
            label="Conciliacion"
            class="mt-4 pt-4"
            clearable
            return-object
            v-model="glRecObj"
            @change="cargarListaEcGlrec()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" class="pt-4 ma-0">
          
          <v-select
            v-model="finAccountId"
            clearable
            label="Cuenta bancaria"
            class="mt-5 pt-4 px-4"
            :items="cuentasBancarias"
            item-text="fin_account_name"
            item-value="fin_account_id"
            @change="cargarListaEstadoCuenta(true)"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-btn @click="conciliar()" color="green" dark small title="conciliar"
            ><v-icon>mdi-cogs</v-icon>Conciliar</v-btn
          >
        </v-col>
      </v-toolbar>
      <v-row class="px-4">
        <v-col cols="2">
          <v-select
            small
            v-model="searchTipo"
            clearable
            label="Tipo trasacción"
            class="mt-3"
            :items="tiposTransac"
            item-text="tipo"
            item-value="tipo_id"
            @change="cargarListaEstadoCuenta(true)"
          ></v-select>
        </v-col>

        <v-col cols="2">
          <v-text-field
            small
            v-model="searchRef"
            append-icon="mdi-magnify"
            label="Documento"
            class="mt-3"
            @keydown.enter="cargarListaEstadoCuenta(true)"
            @click:append="cargarListaEstadoCuenta(true)"
          ></v-text-field>
        </v-col>

        <v-col cols="1">
          <v-text-field
          small
            v-model="searchMonto"
            append-icon="mdi-magnify"
            label="Monto"
            class="mt-3"
            @keydown.enter="cargarListaEstadoCuenta(true)"
            @click:append="cargarListaEstadoCuenta(true)"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-select
          small
            v-model="searchEstado"
            label="Estado"
            class="mt-3"
            :items="estados"
            item-text="nombre"
            item-value="estado_id"
            clearable
            @change="cargarListaEstadoCuenta(true)"
          ></v-select>
        </v-col>

        <v-col cols="2">
          <v-text-field
            small
            v-model="searchConcepto"
            append-icon="mdi-magnify"
            label="Concepto"
            class="mt-3"
            @keydown.enter="cargarListaEstadoCuenta(true)"
            @click:append="cargarListaEstadoCuenta(true)"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <table style="border: 1px solid gray; border-collapse: collapse;">
            <tr>
              <th style="background-color:lightgray; padding: 2px; border: 1px solid gray; border-collapse: collapse;"></th>
              <th style="background-color:lightgray; font-size: small; padding: 2px; border: 1px solid gray; border-collapse: collapse;">En estado cta.</th>
              <th style="background-color:lightgray; font-size: small; padding: 2px; border: 1px solid gray; border-collapse: collapse;">En libro</th>
              <th style="background-color:lightgray; font-size: small; padding: 2px; border: 1px solid gray; border-collapse: collapse;">Saldo</th>
            </tr>
            <tr>
              <td style="padding: 2px;border: 1px solid gray; border-collapse: collapse;">Saldo inicial</td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end; ">{{ saldoInicialEc }}</td>
            </tr>

            <tr>                  
              <td style="padding: 2px;border: 1px solid gray; border-collapse: collapse;">Depósitos </td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end;">{{  montoTotalC  }}</td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end;">{{  montoConcilC  }}</td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end;">{{  (montoTotalC - montoConcilC).toFixed(2)   }}</td>
            </tr>
            <tr>
              <td style="padding: 2px;border: 1px solid gray; border-collapse: collapse;">Retiros</td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end; ">{{ montoTotalD }}</td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end;">{{ montoConcilD }}</td>
              <td style="padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end;">{{ (montoTotalD - montoConcilD).toFixed(2) }}</td>
            </tr>
            
            <tr>
              <td style="background-color:lightgoldenrodyellow; padding: 2px;border: 1px solid gray; border-collapse: collapse;">Saldo final</td>
              <td style="background-color:lightgoldenrodyellow; padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end; "> {{ ( saldoInicialEc + (montoTotalC - montoTotalD)).toFixed(2) }}</td>
              <td style="background-color:lightgoldenrodyellow; padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end; "> {{ ( (saldoInicialEc + montoConcilC - montoConcilD)).toFixed(2) }}</td>
              <td style="background-color:lightgoldenrodyellow; padding: 3px;border: 1px solid gray; border-collapse: collapse; text-align: end; "> </td>
            </tr>
          </table>
        </v-col>

        <!-- <v-col cols="2">
            <v-text-field
              v-model="searchAsiento"
              append-icon="mdi-magnify"
              label="Asiento contable"
              single-line
              class="mt-3"
              @keydown.enter="cargarListaEstadoCuenta(true)"
              @click:append="cargarListaEstadoCuenta(true)"
            ></v-text-field>
          </v-col> -->

        <!-- <v-col cols="2">
            <v-text-field
              v-model="searchCaja"
              append-icon="mdi-magnify"
              label="Caja"
              single-line
              class="mt-3"
              @keydown.enter="cargarListaEstadoCuenta(true)"
              @click:append="cargarListaEstadoCuenta(true)"
            ></v-text-field>
          </v-col> -->
      </v-row>
      <v-toolbar flat color="white">
        <v-col cols="3">
          <v-btn
            color="primary"
            small
            class="ma-1 white--text"
            @click="dialogCargaEstadoCuenta = true"
          >
            <v-icon left>mdi-file-upload</v-icon>
            Cargar estado de cuenta
          </v-btn>
          
        </v-col>
        <v-col cols="6" color="black">          
           
    <div style="color:black">
      <table style="border: 1px solid gray; border-collapse: collapse;">
            <tr>
              <td style="background-color: blanchedalmond; padding: 4px; border: 1px solid gray; border-collapse: collapse;">Total Transacciones: </td>
              <th style="padding: 4px;  color:darkslategray; border: 1px solid gray;  border-collapse: collapse;">{{ transConcil + transNoConcil }}</th>

              <td style="background-color: blanchedalmond;padding: 4px; border: 1px solid gray; border-collapse: collapse;">Conciladas</td>
              <th style="padding: 4px; color:darkslategray; border: 1px solid gray; border-collapse: collapse;"> {{ transConcil }}</th>

              <td style="background-color: blanchedalmond;padding: 4px; border: 1px solid gray; border-collapse: collapse;">No conciliadas</td>
              <th style="padding: 4px; border: 1px solid gray; color:darkslategray; border-collapse: collapse;">{{ transNoConcil }}</th>
            </tr>
          </table>
          </div>
        </v-col>  
      </v-toolbar>
      
      <v-row>
        <v-col md="12" sm="12">
          
          <v-data-table
            :headers="headers"
            :items="listaEstadoCuenta"
            :items-per-page=itemsPerPage
            
            :loading="loadingTable"
            hide-default-footer
            class="elevation-1"
            v-model="cecSelected"
            item-key="concil_estado_cuenta_id"
            show-select
          >
            <template v-slot:item.opcion="{ item }">
              <v-btn
                class="mx-1"
                v-if="item.estado == 'CONCILIADO'"
                small
                icon
                target="_blank"
                title="Borrar conciliación"
                @click="borrarConciliacion(item)"
              >
                <v-icon small color="red">mdi-delete</v-icon>
              </v-btn>
              <v-btn
                class="mx-1"
                v-if="item.estado == 'CONCILIADO'"
                icon
                small
                target="_blank"
                title="Borrar conciliación"
                @click="verDetalleConciliacion(item)"
              >
                <v-icon small color="info">mdi-eye</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.fin_account_trans_id="{ item }">
              <v-btn
                small
                text
                @click="editarTransaccion(item)"
                target="_blank"
                title="Ver transacción"
                ><v-icon small left>mdi-eye</v-icon>
                {{ item.fin_account_trans_id }}
              </v-btn>
            </template>

            <template v-slot:item.caja_id="{ item }">
              <v-btn
                v-if="item.caja_id && !item.caja_trans_id"
                icon
                target="_blank"
                :to="`/ver-caja/${item.caja_id}`"
                title="Ver Depósito en efectivo"
              >
                <v-icon small color="green">mdi-cash</v-icon>{{ item.caja_id }}
              </v-btn>

              <v-btn
                v-if="item.caja_id && item.caja_trans_id"
                icon
                target="_blank"
                :to="`/ver-caja/${item.caja_id}`"
                title="Ver depósito de cheque"
              >
                <v-icon small color="green">mdi-checkbook</v-icon
                >{{ item.caja_id }}
              </v-btn>
            </template>
            <template v-slot:item.payment_id="{ item }">
              <v-btn
                v-if="
                  item.payment_id &&
                  item.fin_account_trans_type_id == 'WITHDRAWAL'
                "
                icon
                target="_blank"
                :to="`/ver-pago-emitido/${item.payment_id}`"
                title="Ver pago saliente"
              >
                <v-icon small color="green">mdi-eye</v-icon
                >{{ item.payment_id }}
              </v-btn>
              <v-btn
                v-if="
                  item.payment_id && item.fin_account_trans_type_id == 'DEPOSIT'
                "
                icon
                target="_blank"
                :to="`/ver-pago/${item.payment_id}`"
                title="Ver pago entrante"
              >
                <v-icon small color="green">mdi-eye</v-icon
                >{{ item.payment_id }}
              </v-btn>
            </template>

            <template v-slot:item.referencia="{ item }">
              <span v-if="item.payment_id == null">{{ item.referencia }}</span>
              <span v-if="item.payment_id != null">{{
                item.payment_ref_num
              }}</span>
            </template>

            <template v-slot:item.acctg_trans_id="{ item }">
              <!-- <span v-if="item.payment_id == null">{{
                  item.acctg_trans_id                
                }}</span> -->
              <v-btn
                target="_blank"
                text
                v-if="item.payment_id == null"
                small
                :href="`/asientoContableVista/${item.acctg_trans_id}`"
              >
                <v-icon small color="green">mdi-eye</v-icon
                >{{ item.acctg_trans_id }}</v-btn
              >

              <!-- <span v-if="item.payment_id != null">{{
                  item.acctg_trans_id_pay
                }}</span> -->

              <v-btn
                target="_blank"
                text
                v-if="item.payment_id != null"
                small
                :href="`/asientoContableVista/${item.acctg_trans_id_pay}`"
              >
                <v-icon small color="green">mdi-eye</v-icon
                >{{ item.acctg_trans_id_pay }}</v-btn
              >
            </template>
            <template v-slot:footer>
              <v-pagination
                class="mt-10"
                v-model="currentPage"
                :length="pageCountEc"
                @input="handlePageChange"
                total-visible="10"
              ></v-pagination>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- dialogo de ingreso de transacciones bancarias -->
    <v-dialog v-model="dialogTransaccionBanco" width="600">
      <v-card>
        <v-form ref="formFat">
          <v-toolbar height="40" color="blue" dark flat>
            <v-toolbar-title>Transacción bancaria</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              small
              dark
              icon
              @click="dialogTransaccionBanco = !dialogTransaccionBanco"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>

          <v-row class="pa-2 ma-2">
            <v-col cols="12" class="pa-0 ma-0">
              <v-select
                v-model="datosFat.fin_account_id"
                :rules="requiredRule"
                label="Cuenta bancaria"
                item-text="fin_account_name"
                item-value="fin_account_id"
                :disabled="editarFat == true"
                :items="cuentasBancarias"
              ></v-select>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12">
              <v-select
                :rules="requiredRule"
                v-model="datosFat.fin_account_trans_type_id"
                :items="tiposTransacLb"
                item-text="tipo"
                item-value="tipo_id"
                :disabled="editarFat == true"
                label="Tipo de transacción"
              ></v-select>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12">
              <v-select
                :rules="editarFat == false ? requiredRule : []"
                v-model="datosFat.gl_account_id"
                :items="cuentasContables"
                item-text="nombre_cuenta"
                item-value="gl_account_id"
                :disabled="editarFat == true"
                label="Cuenta contable"
              >
              </v-select>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12">
              <v-text-field
                :rules="requiredRule"
                v-model="datosFat.transaction_date"
                :disabled="editarFat == true"
                type="date"
                label="Fecha"
              ></v-text-field>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12">
              <v-text-field
                v-model="datosFat.referencia"
                label="Referencia"
              ></v-text-field>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12">
              <v-textarea
                label="Observaciones"
                v-model="datosFat.comments"
                rows="2"
              ></v-textarea>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12">
              <v-text-field
                :disabled="editarFat == true"
                :rules="requiredRule"
                v-model="datosFat.amount"
                label="Monto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col class="pt-4 ma-0">
              <v-btn
                class="mx-4"
                dark
                small
                v-if="editarFat == false"
                color="green"
                @click="ingresarFat()"
                ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
              >
              <v-btn
                class="mx-4"
                dark
                small
                v-if="editarFat == true"
                color="green"
                @click="modificarFat()"
                ><v-icon>mdi-content-save</v-icon>Modificar</v-btn
              >

              <v-btn
                class="mx-4"
                dark
                small
                color="blue"
                @click="dialogTransaccionBanco = !dialogTransaccionBanco"
                ><v-icon>mdi-cancel</v-icon>Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- dialogo de carga de estado de cuenta-->
    <v-dialog v-model="dialogCargaEstadoCuenta" width="500">
      <v-card>
        <v-form ref="formCecUpload">
          <v-toolbar height="40" color="blue" dark flat>
            <v-toolbar-title>Cargar estado de cuenta</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              small
              dark
              icon
              @click="dialogCargaEstadoCuenta = !dialogCargaEstadoCuenta"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pt-4 ma-0">
              <v-select
                :rules="requiredRule"
                v-model="searchCuenta"
                clearable
                label="Cuenta bancaria"
                class="mt-5 pt-4 px-4"
                :items="cuentasBancarias"
                item-text="fin_account_name"
                item-value="fin_account_id"
                @change="cargarListaEstadoCuenta(true)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pt-4 ma-0">
              <v-file-input
                :rules="requiredRule"
                v-model="archivoEstadoCuenta"
                label="Selecciona el archivo"
                outlined
                dense
                prepend-icon=""
                accept="text/*"
                class="mx-3 mt-4"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pt-4 ma-0">
              <v-btn
                color="primary"
                small
                class="px-4 ma-2 white--text"
                @click="importar()"
              >
                <v-icon left>mdi-upload</v-icon>
                Cargar estado de cuenta
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- dialogo detalle de  conciliacion -->
    <v-dialog v-model="dialogDetalleConc" width="500">
      <v-card>
        <v-toolbar height="40" color="blue" dark flat>
          <v-toolbar-title>Detalle de conciliacion</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small dark icon @click="dialogDetalleConc = !dialogDetalleConc"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row v-if="itemsDetalle > 1">
          <v-col>
            <!-- table de items de libro banco -->
            <h3 class="mx-4">Libro banco</h3>
            <v-data-table
              :headers="headersDetalleConcil"
              :items="datosFatLista"
              :items-per-page="20"
              :loading="loadingTable"
              class="elevation-1"
            ></v-data-table>
          </v-col>
        </v-row>

        <v-row v-if="itemsDetalle == 1" class="pa-2 ma-2">
          <v-col cols="6" class="pa-0 ma-0">
            <v-text-field
              readonly
              v-model="datosFat.fin_account_trans_id"
              label="Código"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pa-0 ma-0">
            <v-text-field
              readonly
              v-model="datosFat.acctg_trans_id_pay"
              label="Asiento contable"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0">
            <v-select
              readonly
              v-model="datosFat.fin_account_id"
              :rules="requiredRule"
              label="Cuenta bancaria"
              item-text="fin_account_name"
              item-value="fin_account_id"
              :disabled="editarFat == true"
              :items="cuentasBancarias"
            ></v-select>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-select
              readonly
              :rules="requiredRule"
              v-model="datosFat.fin_account_trans_type_id"
              :items="tiposTransacLb"
              item-text="tipo"
              item-value="tipo_id"
              :disabled="editarFat == true"
              label="Tipo de transacción"
            ></v-select>
          </v-col>

          <!-- <v-col class="pa-0 ma-0" cols="12">
              <v-select :rules="(editarFat == false ? requiredRule : [])" v-model="datosFat.gl_account_id"
                :items="cuentasContables" item-text="nombre_cuenta" item-value="gl_account_id"
                :disabled="editarFat == true" label="Cuenta contable">
              </v-select>
            </v-col> -->

          <v-col class="pa-0 ma-0" cols="12">
            <v-text-field
              readonly
              :rules="requiredRule"
              v-model="datosFat.transaction_date"
              :disabled="editarFat == true"
              type="date"
              label="Fecha"
            ></v-text-field>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-textarea
              readonly
              label="Observaciones"
              v-model="datosFat.comments"
              rows="2"
            ></v-textarea>
          </v-col>

          <v-col class="pa-0 ma-0" cols="6">
            <v-text-field
              v-if="datosFat.payment_id == null"
              readonly
              v-model="datosFat.referencia"
              label="Referencia"
            ></v-text-field>
            <v-text-field
              v-if="datosFat.payment_id != null"
              readonly
              v-model="datosFat.payment_ref_num"
              label="Referencia"
            ></v-text-field>
          </v-col>

          <v-col class="pa-0 ma-0" cols="6">
            <v-text-field
              readonly
              :disabled="editarFat == true"
              :rules="requiredRule"
              v-model="datosFat.amount"
              label="Monto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pt-4 ma-0">
            <v-btn
              color="primary"
              small
              class="px-4 ma-2 white--text"
              @click="dialogDetalleConc = !dialogDetalleConc"
            >
              <v-icon left>mdi-close</v-icon>
              Salir
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
import axios from "axios";
import moment from "moment";

export default {
  name: "EstadoCuentaBancos",
  components: {
    DatePicker,
  },

  data: () => ({
    headers: [
      { text: "Opcion", value: "opcion", align: "center", width: 90 },
      { text: "Tipo", value: "tipo", width: 30 },
      { text: "Fecha", value: "fecha", width: 90 },
      { text: "Concepto", value: "concepto", align: "left", width: 190 },
      { text: "Documento", value: "documento", width: 90 },
      { text: "Estado", value: "estado", align: "left", width: 80 },
      {
        text: "Conciliado por",
        value: "conciliado_por",
        align: "left",
        width: 90,
      },
      { text: "Monto", value: "monto", align: "right", width: 70 },
      { text: "Saldo", value: "saldo", align: "right", width: 70 },
    ],
    lista: [],
    listaEstadoCuenta: [],
    requiredRule: [(v) => !!v || "El campo es requerido"],
    headersLibroBanco: [
      { text: "Opcion", value: "opcion", align: "center", width: 30 },
      { text: "Transaccion", value: "fin_account_trans_id", width: 30 },
      { text: "Tipo trans.", value: "tipo_tran", width: 30 },
      { text: "Fecha trans.", value: "transaction_date", width: 80 },
      { text: "Fecha reg.", value: "entry_date", width: 80 },
      { text: "Monto", value: "amount", align: "right" },
      { text: "Referencia", value: "referencia", align: "left" },
      { text: "Pago", value: "payment_id" },
      { text: "Caja", value: "caja_id" },
      { text: "Asiento contable", value: "acctg_trans_id", align: "left" },
      { text: "Comentario", value: "comments", align: "left" },
    ],
    showTimeRangePanel: false,
    showTimePanel: false,
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,
    archivoEstadoCuenta: null,
    searchTipo: "",
    searchCaja: "",
    depCaja: false,

    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 50,

    pageCountEc: 1,
    totalItemsEc: 0,

    tipo: "",
    desde: "",
    hasta: "",
    //estado: "CREADO",
    estados: [
      { estado_id: "CREADO", nombre: "CREADO" },
      { estado_id: "CONCILIADO", nombre: "CONCILIADO" },
    ],
    searchEstado: "",
    searchRef: "",
    searchFecha: [],
    searchMonto: "",
    searchAsiento: "",
    searchCuenta: "",
    searchConcepto: "",
    tipoTransac: "",
    tiposTransac: [
      { tipo_id: "C", tipo: "Depósito" },
      { tipo_id: "D", tipo: "Retiro" },
    ],
    tiposTransacLb: [
      { tipo_id: "DEPOSIT", tipo: "Depósito" },
      { tipo_id: "WITHDRAWAL", tipo: "Retiro" },
    ],
    cuentasBancarias: [],
    dialogTransaccionBanco: false,
    datosTransaccion: {},
    datosFat: {},
    datosFatLista: [],

    cuentasContables: [],
    overlay: false,
    editarFat: false,
    glRec: [],
    glRecId: "",
    glRecObj: null,
    cecSelected: [],
    dialogCargaEstadoCuenta: false,
    dialogDetalleConc: false,
    itemsDetalle: 0,
    headersDetalleConcil: [
      { text: "Transaccion", value: "fin_account_trans_id", width: 30 },
      { text: "Pago", value: "payment_id", width: 30 },
      { text: "Fecha", value: "transaction_date", width: 30 },
      { text: "Referencia", value: "referencia", width: 30 },
      { text: "Monto", value: "amount", width: 30 },
    ],
    finAccountId: "",

    transConcil: 0,
    transNoConcil: 0,
    montoConcilD: 0,
    montoConcilC: 0,
    montoNoConcilD: 0,
    montoNoConcilC: 0,
    montoTotalD: 0,
    montoTotalC: 0,
    saldoInicialEc:0,
    saldoFinalEc:0,
    saldoFinalLb:0

  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId", "token", "url"]),
    ...mapGetters("access", ["btnAbrirCaja"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",      
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
      "setDrawer",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLibroBanco(filtrar) {
      if (filtrar == true) {
        this.currentPage = 1;
      }
      this.setLoadingTable(true);
      this.overlay = true;
      //console.log(this.searchFecha);
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,          
          page_size: this.itemsPerPage,
          tipo: this.searchTipo,
          transac_desde: this.searchFecha[0],
          transac_hasta: this.searchFecha[1],
          estado: this.estado,
          referencia: this.searchRef,
          monto: this.searchMonto,
          asiento: this.searchAsiento,
          fin_account_id: this.searchCuenta,
          caja_id: this.searchCaja,
          dep_caja: this.depCaja,
        },
      })
        .then((res) => {
          //console.log(res.data.lista);
          this.lista = res.data._embedded.libro_bancos;
          this.pageCount = res.data.page_count;
          
          this.setLoadingTable(false);
          this.overlay = false;
          this.dialogTransaccionBanco = false;
        })
        .catch(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        })
        .finally(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    cargarListaEstadoCuenta(filtrar) {
      this.overlay = true;
      if (filtrar == true) {
        this.currentPage = 1;
      }
      this.setLoadingTable(true);

      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,          
          page_size: this.itemsPerPage,
          tipo: this.searchTipo,
          desde: this.searchFecha[0],
          hasta: this.searchFecha[1],
          estado: this.searchEstado,
          gl_rec_id: this.glRecId,
          fin_account_id: this.searchCuenta,
          referencia: this.searchRef,
          monto: this.searchMonto,
          concepto: this.searchConcepto,
        },
      })
        .then((res) => {
          //console.log(res.data.lista);
          this.listaEstadoCuenta = res.data.detail.datos;
          //this.listaEstadoCuenta = res.data._embedded.estado_cuenta;
          this.pageCountEc = res.data.detail.page_count.pageCount;
          this.totalItemsEc = res.data.detail.page_count.totalItemsCount;
          this.transConcil	= res.data.detail.transConcil;
          this.transNoConcil = res.data.detail.transNoConcil;
          this.montoConcilD = res.data.detail.montoConcilD;
          this.montoConcilC = res.data.detail.montoConcilC;
          this.montoNoConcilD = res.data.detail.montoNoConcilD;
          this.montoNoConcilC = res.data.detail.montoNoConcilC;
          this.saldoInicialEc = res.data.detail.saldoIni;

          this.montoTotalD = res.data.detail.montoTotalD;
          this.montoTotalC = res.data.detail.montoTotalC;
          this.setLoadingTable(false);
          this.overlay = false;
        })
        .catch((res) => {
          this.setLoadingTable(false);
          this.overlay = false;
        })
        .finally(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    exportar() {
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "exportarLibroBanco",
          tipo: this.searchTipo,
          transac_desde: this.searchFecha[0],
          transac_hasta: this.searchFecha[1],
          estado: this.estado,
          referencia: this.searchRef,
          monto: this.searchMonto,
          asiento: this.searchAsiento,
          fin_account_id: this.searchCuenta,
          caja_id: this.searchCaja,
          dep_caja: this.depCaja,
        },
      })
        .then((res) => {
          console.log(res.data);

          var a = document.createElement("a");
          a.href =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            res.data.detail.datos;
          a.download = res.data.detail.archivo;
          a.click();
        })
        .then(() => {});
    },

    suma(col) {
      return this.lista
        .reduce(function (a, b) {
          if (b["signo"] == "pos") {
            return a + (b[col] || 0);
          } else {
            return a + (b[col] * -1 || 0);
          }
        }, 0)
        .toFixed(2);
    },

    cargarCuentasBancarias() {
      this.setLoadingTable(true);
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "datosConciliacion",
        },
      }).then((res) => {
        this.cuentasBancarias = res.data.detail.cuentas_bancarias;
        this.cuentasContables = res.data.detail.cuentas_hijas;
        this.glRec = res.data.detail.conciliaciones;
        this.setLoadingTable(false);
      });
    },
    ingresarFat() {
      if (!this.$refs.formFat.validate()) {
        return false;
      }
      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "ingresarFat",
          datosFat: this.datosFat,
        },
      })
        .then((res) => {
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        })
        .finally(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    modificarFat() {
      if (!this.$refs.formFat.validate()) {
        return false;
      }

      this.setUrl("libro-bancos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "modificarFat",
          datosFat: this.datosFat,
        },
      })
        .then((res) => {
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        })
        .catch(() => {
          this.setLoadingTable(false);
          this.cargarLibroBanco(false);
        })
        .finally(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    handlePageChange(value) {
      this.currentPage = value
      this.cargarListaEstadoCuenta(false)
    },

    nuevaTransaccion() {
      this.datosFat = {};
      this.editarFat = false;
      this.dialogTransaccionBanco = true;
    },

    editarTransaccion(item) {
      this.datosFat = item;
      this.editarFat = true;
      this.dialogTransaccionBanco = true;
    },

    borrarFat(item) {
      Vue.swal({
        html: "Está seguro de eliminar esta transacción ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;
          this.setUrl("libro-bancos");
          this.requestApi({
            method: "POST",
            data: {
              accion: "anularFat",
              fin_account_trans_id: item.fin_account_trans_id,
            },
          })
            .then((res) => {
              console.log(res);

              this.alertNotification({
                param: {
                  html: res.data.detail,
                },
              });
              this.overlay = false;
              this.cargarLibroBanco(false);
            })
            .catch((res) => {
              this.overlay = false;
            })
            .finally(() => {
              this.overlay = false;
            });
        }
      });
    },

    importar() {
      if (!this.$refs.formCecUpload.validate()) {
        return false;
      }
      this.overlay = true;
      this.setUrl("estado-cuenta");
      let formData = new FormData();
      formData.append("accion", "cargarEstadoCuenta");
      formData.append("archivo", this.archivoEstadoCuenta);
      formData.append("tenantId", this.tenantId);
      formData.append("user", this.user.user_ligin_id);
      formData.append("pass", this.user.pass);
      formData.append("finAccountId", this.searchCuenta);
      //formData.append('type','xml')

      axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.detail,
              timer: 130000,
            },
          });
          this.overlay = false;
        })
        .catch((err) => {
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    cargarListaEcGlrec() {
      //console.log(this.glRecObj);
      if (this.glRecObj != null) {
        this.glRecId = this.glRecObj.gl_reconciliation_id;
        this.searchFecha[0] = this.glRecObj.fecha_desde;
        this.searchFecha[1] = this.glRecObj.fecha_hasta;
      } else {
        this.searchFecha = [];
        this.estado_cuenta = [];
        this.glRecId = null
        //this.searchFecha = [moment(new Date('2025-01-09T00:00:00')).format('YYYY/MM/DD h:mm'),moment(new Date('2025-01-29T23:45:00')).format('YYYY/MM/DD h:mm')]
      }
      this.cargarListaEstadoCuenta(false);
    },

    borrarConciliacion(item) {
      Vue.swal({
        html: "Está seguro de eliminar esta conciliación ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;
          this.setUrl("estado-cuenta");
          this.requestApi({
            method: "POST",
            data: {
              accion: "borrarConciliacionEc",
              cec_id: item.concil_estado_cuenta_id,
            },
          })
            .then((res) => {
              console.log(res);

              this.alertNotification({
                param: {
                  html: res.data.detail,
                },
              });
              this.overlay = false;
              this.cargarListaEstadoCuenta(false);
            })
            .catch((res) => {
              this.overlay = false;
            })
            .finally(() => {
              this.overlay = false;
            });
        }
      });
    },
    verDetalleConciliacion(item) {
      this.dialogDetalleConc = true;
      this.overlay = true;
      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "POST",
        data: {
          accion: "detalleConciliacion",
          concilEcId: item.concil_estado_cuenta_id,
        },
      })
        .then((res) => {
          //console.log(res)
          if (res.data.detail.total == 1)
            this.datosFat = res.data.detail.datos_libro[0];
          if (res.data.detail.total > 1)
            this.datosFatLista = res.data.detail.datos_libro;
          if (res.data.detail.total == 0) {
            this.datosFat = {};
            this.datosFatLista = [];
          }
          this.itemsDetalle = res.data.detail.total;
        })
        .catch((res) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    conciliar() {
      if(this.finAccountId == "" || this.finAccountId == null) {
        alert('Seleccione una cuenta bancaria');
        return false;
      }
      if(this.glRecId == "" || this.glRecId == null) {
        alert('Seleccione una conciliacion');
        return false;
      }
      this.overlay = true;

      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "POST",
        data: {
          accion: "conciliarCuenta",
          finAccountId: this.finAccountId,
          glRecId: this.glRecId,
        },
      })
        .then((res) => {
          
      
        })
        .catch((res) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
  },

  mounted() {
    this.setTitleToolbar("ESTADO CUENTA BANCO");
    //this.cargarLibroBanco(false);
    //this.cargarListaEstadoCuenta(false)
    this.setDrawer(false);
    this.cargarCuentasBancarias();
  },

  watch: {
    /*searchNumber: {
      handler() {
        this.cargarLista(true);
      },
    },
    searchEstado: {
      handler() {
        this.cargarLista(true);
      },
    },*/
  },
};
</script>
